import React,{useState,Fragment,useEffect} from 'react';
import AmbassadorKeyStats from './AmbassadorKeyStats';
import Cookies from 'js-cookie';
import axios from 'axios';
import AmbassadorTable from './AmbassadorTable';
import AmbassadorBarChart from './AmbassadorBarChart';
import AmbassadorLineChart from './AmbassadorLineChart';
import campingImg from "../../assets/img/illustrations/camping.svg"
import {Spinner,Button,Container} from "reactstrap"
import {useHistory} from "react-router-dom"
import {Card, Row, Col} from 'reactstrap'

function AmbassadorDashboard() {
     const [keyStats,setKeystats] = useState({})
     const [keyStatsLoading,setKeyStatsLoading] = useState(true)
     const [searchFilter,setSearchFilter] = useState("");
     const [ambassadorData,setAmbassadorData] = useState([]);
     const [firstNames,setFirstNames] = useState([]);
     const [signups,setSignups] = useState([]) 
     const history = useHistory()

     const [lineChartData, setLineChartData] = useState([]);
     const [loadingChart,setLoadingChart] = useState(false)
     const [pieData, setPieDate] = useState([])

  const searchTable = (rows) => {
    return rows.filter((row) =>
      row.first_name?.toLowerCase().indexOf(searchFilter) > -1 ||
      row.email?.toLowerCase().indexOf(searchFilter) > -1 ||
      row.phone?.toLowerCase().indexOf(searchFilter) > -1 ||
      row.referral_code?.toLowerCase().indexOf(searchFilter) > -1 ||
      row.city?.toLowerCase().indexOf(searchFilter) > -1 ||
      row.town?.toLowerCase().indexOf(searchFilter) > -1 ||
      row.sign_ups?.toString().toLowerCase().indexOf(searchFilter) > -1
    ).reverse()
  }


    useEffect(() =>{
        const getAmbassadors= async ( ) => {
          const token = Cookies.get("ambassador_token");
          await axios
            .get(`https://vizopay-admin-server.herokuapp.com/ambassador/get-ambassadors`, {
              headers: {
                "x-auth-token": token
              }
            })
            .then((res) => {     
                setAmbassadorData(res.data.ambassadors); 
              
            })
            .catch((error) => {
              console.log(error);
              
            });
        }
      
        getAmbassadors(); 
      },[])
    
      const getChartData= async ( ) => {
          console.log("I am running!")
        const token = Cookies.get("ambassador_token");
        await axios
          .get(`https://vizopay-admin-server.herokuapp.com/ambassador/getChartData`, {
            headers: {
              "x-auth-token": token
            }
          })
          .then((res) => {     
             
               setFirstNames(res.data.chartData.first_name); 
               setSignups(res.data.chartData.sign_ups)
            setLoadingChart(true)
          })
          .catch((error) => {
            console.log(error,"Error");
            setLoadingChart(true)
          });
      }

  const getLineChartData= async () => {
          console.log("I am running!")
        const token = Cookies.get("ambassador_token");
        await axios
          .get(`https://vizopay-admin-server.herokuapp.com/ambassador/line-chart-data`, {
            headers: {
              "x-auth-token": token
            }
          })
          .then((res) => {     
              console.log(res.data, "RESPONSE FROM TOTAL LINE CHART")
              setLineChartData(res.data.data);
              
          })
          .catch((error) => {
            console.log(error,"Error");
            
          });
      }
      
    

    useEffect(() =>{
        const getKeystats= async ( ) => {
          const token = Cookies.get("ambassador_token");
          await axios
            .get(`https://vizopay-admin-server.herokuapp.com/ambassador/keystats`, {
              headers: {
                "x-auth-token": token
              }
            })
            .then((res) => {
              
             setKeystats(res.data.keyStats);
              setKeyStatsLoading(false)
              
              
            })
            .catch((error) => {
              console.log(error);
              setKeyStatsLoading(false)
            });
        }
      
        getKeystats(); 
        getChartData(); 
        getLineChartData()
      },[])
    
      const changeTOCreate = () => {
        let path = `/ambassador/create`
    
        console.log(path, "path")
    
        history.push(path)
      } 



  return <Fragment style={{MaxWith:"80wv"}}>
  <Row>
    <Col>
     <div className="py-4" style={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       width: '100%',
       flexDirection: 'column'
     }}>
        <h2>Hey there 👋</h2>
      <span>Let's smash it this week 💪</span>
     </div>
    </Col>
  </Row>
<AmbassadorKeyStats keyStats={keyStats} />
<Button onClick={changeTOCreate} style={{marginBottom:"20px"}}>
    Create Ambassador
</Button>
{
  firstNames.length?

<AmbassadorTable ambassadorData={searchTable(ambassadorData)} setSearchFilter={setSearchFilter} searchFilter={searchFilter}/>
:

<div style={{minWidth:"80vw"}}>
<h2   style={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       width: '100%',
       flexDirection: 'column',
     }}>
  No Ambassadors 
</h2>
<Container style={{maxWidth:"600px",objectFit:"scaleDown",display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
<img src={campingImg} alt="Camping Img" style={{display:"flex",justifyContent:"center",}}/> 
<Button style={{
       display: 'flex',
       justifyContent: 'center',
       alignItems: 'center',
       width: '30%',
       marginLeft:"200px"
     }}>Invite Ambassadors</Button>
</Container>
</div>
}
{
  firstNames.length?
  <div>
    {

    
    loadingChart ?

   <Row>
     <Col>
        <Card className="mb-4 p-4">
        <h4>Sign ups by Ambassadors</h4>
      <AmbassadorBarChart firstNames={firstNames} signups={signups}/>
    </Card>
     </Col>
      <Col>
        <Card className="mb-4 p-4">
        <h4>Total Sign ups</h4>
      <AmbassadorLineChart data={lineChartData}/>
    </Card>
     </Col>
   </Row>

    :

    <div style={{
      display: "block",
position: "fixed",
zIndex: "1031", /* High z-index so it is on top of the page */
top: "50%",
right: "50%", /* or: left: 50%; */
marginTop: "-..px", /* half of the elements height */
marginRight: "-..px"/* half of the elements widht */
     }}><Spinner color="primary" /></div>
  }
    </div>
    :
    null

}
  </Fragment>;
}

export default AmbassadorDashboard;
