import React,{useState,useEffect} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { AuthContext, UserContext } from "./context/Context.js";
import url from "./api/url.js";
import Cookies from "js-cookie";
import axios from "axios";
import { Spinner } from 'reactstrap';


const App = () => {
  const [keystats, setKeystats] = useState({});
  const [auth, setAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  


  useEffect(() => {
    const token = Cookies.get("token");
    const authenticate = async () => {
      await axios
        .get(`${url}/data/key-stats`, {
          headers: {
            "x-auth-token": token
          }
        })
        .then((res) => {
          setKeystats(res.data);
          setAuth(true);
          setLoading(false)
          
        })
        .catch((error) => {
          setAuth(false);
          setLoading(false)
          console.log(error, "error at auth")
        });    
    };

    if(token) {
      authenticate();

    } else {
      setLoading(false);
    }
   
    
  }, [keystats]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <UserContext.Provider value={{ keystats, setKeystats }}>
        <Router basename={process.env.PUBLIC_URL}>
         {
           loading ?

           <div style={{
            display: "block",
  position: "fixed",
  zIndex: "1031", /* High z-index so it is on top of the page */
  top: "50%",
  right: "50%", /* or: left: 50%; */
  marginTop: "-..px", /* half of the elements height */
  marginRight: "-..px"/* half of the elements widht */
           }}><Spinner color="primary" /></div>
           :
           <Layout />
         }
        </Router>
      </UserContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;