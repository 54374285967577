import React, { Fragment, useEffect, useState,useContext } from 'react';
import { Row, Col, Card, CardBody, Button, InputGroup, CustomInput,Spinner } from 'reactstrap';
import CountUp from 'react-countup';
import CardSummary from './CardSummary';
import ActiveUsersBarChart from './ActiveUsersBarChart';
import PaymentsLineChart from './PaymentsLineChart';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import { AuthContext } from '../../context/Context';
import Cookies from 'js-cookie';
import axios from 'axios';
import url from '../../api/url';
import loadable from '@loadable/component';

import { UserContext } from '../../context/Context';
const PurchasesTable = loadable(() => import('./PurchasesTable'));
const ActiveUsersMap = loadable(() => import('./ActiveUsersMap'));

const Dashboard = () => {
  // State
  const [isSelected, setIsSelected] = useState(false);
  const [keystats, setKeystats] = useState({});
  const [Loading,setLoading] = useState(true)
  
  useEffect(() => {
    const token = Cookies.get("token");
    const authenticate = async () => {
      await axios
        .get(`${url}/data/key-stats`, {
          headers: {
            "x-auth-token": token
          }
        })
        .then((res) => {
          setKeystats(res.data);
          setLoading(false)
        })
        .catch((error) => {
          console.log(error, "error at DashBoard")
        });    
    };

    if(token) {
      authenticate();

    } else {
      return
    }


    
  }, []);
  

  useEffect(() => {
    toast(
      <Fragment>
        Welcome to the<strong> Admin Dashboard</strong>!<br />
      </Fragment>
    );
  }, []);

  return (
    <Fragment>
      <PaymentsLineChart />
      {
        Loading?
        <div className="card-deck">
        <Spinner style={{
          display: "block",
position: "fixed",
zIndex: "1031", /* High z-index so it is on top of the page */
top: "50%",
right: "50%", /* or: left: 50%; */
marginTop: "-..px", /* half of the elements height */
marginRight: "-..px"/* half of the elements widht */
         }} color="primary" />
         </div>
        :
        <>
        <div className="card-deck">
        <CardSummary  title="Merchants" color="success" linkText="See all" to="/merchants">
        {keystats.keystats?.total_merchants} 
        </CardSummary>
        <CardSummary  title="Users" color="success" linkText="See all" to="/users">
        {keystats.keystats?.total_users} 
        </CardSummary>
        <CardSummary content="43,594"  title="Revenue" color="success" linkText="Details" to="/payments">
        £{(keystats.keystats?.total_revenue/100).toFixed(2)}
        </CardSummary>
        <CardSummary  title="Trees Planted" color="success">
         {keystats.keystats?.total_trees_planted.toFixed(0)} 
        </CardSummary>
      </div>
      <div className="card-deck">
        <CardSummary  title="Active Stores" color="success" linkText="See all" to="/stores">
        {keystats.keystats?.total_active_stores} 
        </CardSummary>
        <CardSummary content="43,594" title="Donated" color="success" >
        £{(keystats.keystats?.total_donated/100).toFixed(2)} 
        </CardSummary>
        <CardSummary title="Number of Successful transactions" color="success" >
        {keystats.keystats?.total_number_successful_transactions}
        </CardSummary>
        <CardSummary  title="Carbon Offset" color="success" >
        {(keystats.keystats?.total_carbon_offset/1000).toFixed(2)}t
        </CardSummary>
      </div>
      <div className="card-deck">
        <CardSummary content="43,594"  title="Total Volume" color="success" linkText="Details" to="/payments">
        £{(keystats.keystats?.total_volume/100).toFixed(2)}
        </CardSummary>
        <CardSummary title="Average Transaction Size" color="success" >
        £{(keystats.keystats?.average_transaction_size/100).toFixed(2)}
        </CardSummary>
      </div>
      </>
      }
      
    </Fragment>
  );
};

export default Dashboard;
