import React,{Fragment} from 'react';
import CardSummary from '../dashboard/CardSummary';



function AmbassadorKeyStats(keyStats) {
  return <Fragment>
      <div className="card-deck" style={{display:"flex",alignItems:"center"}}>
        <CardSummary  title="No of Ambassadors" color="success"  >
        {keyStats.keyStats?.no_of_ambassadors} 
        </CardSummary>
        <CardSummary  title="Total Signups" color="success" >
        {keyStats.keyStats?.total_signups} 
        </CardSummary>
        <CardSummary content="43,594"  title="Signups This Week" color="success" >
        {keyStats.keyStats?.total_signups_week}
        </CardSummary>
        <CardSummary content="43,594"  title="Personal Signups" color="success" >
        {keyStats.keyStats?.cgl_signups}
        </CardSummary>
        {/* <CardSummary content="43,594"  title="Weekly Goal" color="success" >
        {Math.round(250/keyStats.keyStats?.no_of_ambassadors)}
        </CardSummary> */}
      </div>
  </Fragment>;
}

export default AmbassadorKeyStats;
