import React,{useState,Fragment} from 'react';
import AmbassadorLogin from './AmbassadorLogin';
import { Col, Row, Card, CardBody, Container, Spinner } from 'reactstrap';
import Logo from '../navbar/Logo';
import Section from '../common/Section';
import AmbassadorDashboard from './AmbassadorDashboard';

import axios from 'axios';
import url from '../../api/url';
import Cookies from 'js-cookie';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import NavbarTop from './NavbarTop';


function AmbassadorLayout() {
    const [ambassadorAuth,setAmbassadorAuth] = useState(false);
    
    const [loader, setLoader] = useState(false)

    React.useEffect(() => {

      let token = Cookies.get("ambassador_token")

      const authenticate = async() => {
        await axios.get(`${url}/ambassador/authenticate`, {
          headers: {
            "x-auth-token": token
          }
        })
        .then(res => {
          if(res.data.auth) {
            setAmbassadorAuth(true)
            setLoader(false)
          } else {
            setAmbassadorAuth(false)
            setLoader(false)
          }
        })
        .catch(err => {
          console.log(err.response, "AUTHENTICATE AMBASSADOR")
          setLoader(false)
          setAmbassadorAuth(false)
        })
      }

      authenticate()



    }, [])

  return <>
  {
    ambassadorAuth?
    <Container>
    <NavbarTop setAmbassadorAuth={setAmbassadorAuth}/>
    </Container>
    :
    null

  }
      {
          loader ?

          <Container>
            <div style={{
              display: "block",
    position: "fixed",
    zIndex: "1031", /* High z-index so it is on top of the page */
    top: "50%",
    right: "50%", /* or: left: 50%; */
    marginTop: "-..px", /* half of the elements height */
    marginRight: "-..px"/* half of the elements widht */
             }}><Spinner color="primary" /></div>
          </Container>

          :

          ambassadorAuth?
          <Container >
            <AmbassadorDashboard/>
          </Container>
          :
         <Section className="py-0">
         <Row className="flex-center min-vh-100 py-6">
           <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
             <Logo />
             <Card>
               <CardBody className="fs--1 font-weight-normal p-5">
               <Fragment>
          <Row className="text-left justify-content-between">
            <Col xs="auto">
              <h5>Ambassador Log in</h5>
            </Col>
          </Row>
          <AmbassadorLogin setAmbassadorAuth={setAmbassadorAuth}/>
        </Fragment>
               </CardBody>
             </Card>
           </Col>
         </Row>
       </Section>
        
      }
  </>
}

export default AmbassadorLayout;
