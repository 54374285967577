import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState,useContext } from 'react';
import { AuthContext } from '../../context/Context';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import team3 from '../../assets/img/team/3.jpg';
import Avatar from '../common/Avatar';

const ProfileDropdown = ({setAmbassadorAuth}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLogout = () => {
    Cookies.remove('ambassador_token');
    setAmbassadorAuth(false)
  }
  const toggle = () => setDropdownOpen(prevState => !prevState);
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
         <FontAwesomeIcon icon="user" /> 
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          {
            /*
            <DropdownItem tag={Link} to="/pages/settings">
              Settings
            </DropdownItem>   
            */ 
          }

          <DropdownItem onClick= {handleLogout} tag={Link} to="/ambassador/login">
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropdown;
