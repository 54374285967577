import React from 'react'

// import the core library.
import ReactEChartsCore from 'echarts-for-react/lib/core';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';

// Import charts, all with Chart suffix
import { BarChart } from 'echarts/charts';

// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';

// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([GridComponent, TooltipComponent, TitleComponent, LegendComponent])

const getColor = (props) => {

    switch (props) {
        case "dark":
            return "#222831"
            break;

        case "white":
            return "#fff"
            break;

        case "warning":
            return "#FF9F45"
            break;
    
        case "info":
            return "#8A39E1"
            break;

        default:
            return "#65ccb8"

            break;
    }

}


const tooltipFormatter = params => {
    console.log(params[1]?.value)
    console.log(params[0])
  return `
<div>
    <h6 class="fs--1 text-700 mb-0">
    <span class="dot me-1 d-inline-block" style="background-color:${
      !params[0] ? "#fff" : params[0].borderColor ? params[0].borderColor : params[0].color
    }"></span>
      ${params[0].name} : ${!params[0]?.value ? "" : params[0]?.value}
    </h6>
</div>
`;
};


    function AmbassadorLineChart({data}) {
  const months = [
    'Week 1',
    'Week 2',
    'Week 3',
    'Week 4',
    'Week 5',
    'Week 6',
    'Week 7',
    'Week 8',
    'Week 9',
    'Week 10',
    'Week 11',
    'Week 12'
  ];

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: getColor('500')
        },
        label: {
          show: true,
          backgroundColor: "#65ccb8",
          color: "#fff"
        }
      },
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: tooltipFormatter
    },
    toolbox: {
      top: 0,
      feature: {
        dataView: { show: false },
        magicType: {
          show: true,
          type: ['line', 'bar']
        },
        restore: { show: true },
        saveAsImage: { show: true }
      },
      iconStyle: {
        borderColor: getColor('700'),
        borderWidth: 1
      },

      emphasis: {
        iconStyle: {
          textFill: getColor('600')
        }
      }
    },
    legend: {
      top: 40,
      data: ['Evaporation', 'Total Sign Ups', 'Target Sign Ups'],
      textStyle: {
        color: getColor('600')
      }
    },
    xAxis: [
      {
        type: 'category',
        data: months,
        axisLabel: {
          color: getColor('600'),
          formatter: value => value.slice(0, 3)
        },
        axisPointer: {
          type: 'shadow'
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: getColor('300')
          }
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 4000,
        interval: 250,
        axisLabel: {
          color: getColor('600'),
          formatter: '{value}'
        },
        splitLine: {
          show: false,
          lineStyle: {
            color: getColor('200')
          }
        }
      },
    //   {
    //     type: 'value',
    //     min: 0,
    //     max: 25,
    //     interval: 5,
    //     axisLabel: {
    //       color: getColor('600'),
    //       formatter: '{value} °C'
    //     },

    //     splitLine: {
    //       show: false,
    //       lineStyle: {
    //         color: getColor('200')
    //       }
    //     }
    //   }
    ],
    series: [
    //   {
    //     name: 'Evaporation',
    //     type: 'bar',
    //     data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3],
    //     itemStyle: {
    //       color: getColor('primary'),
    //       borderRadius: [3, 3, 0, 0]
    //     }
    //   },
    {
        name: 'Total Sign Ups',
        type: 'line',
        data: data,
        lineStyle: {
          color: getColor('info')
        },
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('info'),
          borderWidth: 2
        },
        symbol: 'circle',
        symbolSize: 10
      },
   {
        name: 'Target Sign Ups',
        type: 'line',
        data: [250, 500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500, 2750, 3000],
        lineStyle: {
          color: getColor('warning')
        },
        itemStyle: {
          color: getColor('white'),
          borderColor: getColor('warning'),
          borderWidth: 2
        },
        symbol: 'circle',
        symbolSize: 5
      }
    ],
    grid: {
      right: 5,
      left: 5,
      bottom: 5,
      top: '23%',
      containLabel: true
    }
  }); 

  return (
    <ReactEChartsCore
    echarts={echarts}
    option={getOption()}
    style={{ height: '21.88rem' }}
  />
  );
}


export default AmbassadorLineChart
