import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch,Redirect } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import { AuthContext } from './../context/Context';
import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';
import PrivateRoute from '../PrivateRoute';
import loadable from '@loadable/component';
import AmbassadorLayout from '../components/ambassadorDashboard/AmbassadorLayout';
import CreateAmbassadorFromCgl from '../components/ambassadorDashboard/CreateAmbassadorFromCgl';
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

const Layout = () => {
  const {auth} = useContext(AuthContext);


  useEffect(() => {
    AuthBasicLayout.preload();

  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
     
        <Route path="/errors" component={ErrorLayout} />
        <Route path="/ambassador/login" component={AmbassadorLayout}/>
        <Route path={"/ambassador/create"} component={CreateAmbassadorFromCgl}/>
        <Route path="/authentication/basic" >
        {
             auth ?

             <Redirect to={{
               pathname:"/",
               state: {from: "/authentication/basic"}
             }}/>
             :
             <AuthBasicLayout />
          }

          </Route>
          <PrivateRoute component={DashboardLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
