import React, { createRef, Fragment, useState,useEffect,useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  Media,
  Row,
  UncontrolledDropdown,
  Input
} from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import ButtonIcon from '../common/ButtonIcon';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Flex from '../common/Flex';
import Avatar from '../common/Avatar';
import { getPaginationArray } from '../../helpers/utils';
import './dataTable.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useHistory} from "react-router-dom"



const AmbassadorTable = ({ambassadorData,setSearchFilter,searchFilter}) => {

  
  const history = useHistory()

const nameFormatter = (dataField,{ avatar, first_name,referral_code }) => {

  const routeChange = () => {
    let path = `/ambassador/${referral_code}`
    console.log(path, "path")
    history.push(path)
  } 

  return (
 
      <Media className="merchant-clicker" onClick={routeChange} tag={Flex} align="center">
        <Media body >
          <h5 className="mb-0 fs--1">{first_name}</h5>
        </Media>
      </Media>
 
  );
};

const emailFormatter = email => <a href={`mailto:${email}`}>{email}</a>;
const phoneFormatter = phone => <a href={`tel:${phone}`}>{phone}</a>;
/*
const actionFormatter = (dataField, { id }: row) => (
  // Control your row with this id
  <UncontrolledDropdown>
    <DropdownToggle color="link" size="sm" className="text-600 btn-reveal mr-3">
      <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
    </DropdownToggle>
    <DropdownMenu right className="border py-2">
      <DropdownItem onClick={() => console.log('Edit: ', id)}>Edit</DropdownItem>
      <DropdownItem onClick={() => console.log('Delete: ', id)} className="text-danger">
        Delete
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
*/



const linkFormatter = (id) => {
  
  return (
  
   <Button onClick={() => {
    navigator.clipboard.writeText(id);
   }}>Copy Url</Button>
    
  )
}


const columns = [
  {
    dataField: 'first_name',
    text: 'Name',
    headerClasses: 'border-0',
    classes: 'border-0 py-2 align-middle',
    sort: true
  },
  {
    dataField: 'email',
    headerClasses: 'border-0',
    text: 'Email',
    classes: 'border-0 py-2 align-middle',
    formatter: emailFormatter,
    sort: true
  },
  {
    dataField: 'phone',
    headerClasses: 'border-0',
    text: 'Phone',
    classes: 'border-0 py-2 align-middle',
    formatter: phoneFormatter,
    sort: true
  },
  {
    dataField: 'referral_code',
    headerClasses: 'border-0',
    text: 'Referral Code',
    classes: 'border-0 py-2 align-middle',
    sort: true
  },
  {
    dataField: 'sign_ups',
    headerClasses: 'border-0',
    text: 'Sign Ups',
    classes: 'border-0 py-2 align-middle',
    sort: true
  },
  {
    dataField: 'city',
    headerClasses: 'border-0',
    text: 'City',
    classes: 'border-0 py-2 align-middle',
    sort: true
  },
  {
    dataField: 'town',
    headerClasses: 'border-0',
    text: 'Town',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    align: 'right',
    headerAlign: 'right'
  },
  {
    dataField: 'unique_link',
    headerClasses: 'border-0',
    text: 'Unique Link',
    classes: 'border-0 py-2 align-middle',
    sort: true,
    formatter:linkFormatter,
    align: 'right',
    headerAlign: 'right'
  },
  {
    dataField: '',
    headerClasses: 'border-0',
    text: '',
    classes: 'border-0 py-2 align-middle',
  }
];



const SelectRowInput = ({ indeterminate, rowIndex, ...rest }) => (
  <div className="custom-control custom-checkbox">
    <input
      className="custom-control-input"
      {...rest}
      onChange={() => {}}
      ref={input => {
        if (input) input.indeterminate = indeterminate;
      }}
    />
    <label className="custom-control-label" />
  </div>
);




  let table = createRef();
  // State
  
  const options = {
    custom: true,
    sizePerPage: 15,
    totalSize: ambassadorData?.length
  };
  const [isSelected, setIsSelected] = useState(false);
  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const onSelect = () => {
    setImmediate(() => {
      setIsSelected(!!table.current.selectionContext.selected.length);
    });
  };


  

  return (
      <>
    
    <Card className="mb-3">
                  
      <FalconCardHeader title="Ambassadors" light={false}>
      <Col>
                
                  <Input onChange={(e) => setSearchFilter(e.target.value.toLowerCase())} value={searchFilter} style={{ border: 'none' }} size="lg" className="vizopay-input-search" type="text" autoFocus="autoFocus" placeholder="Search Table..." />
      </Col> 
      
      </FalconCardHeader>
      <CardBody className="p-0">
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => {
            const lastIndex = paginationProps.page * paginationProps.sizePerPage;
            return (
              <Fragment>
                <div className="table-responsive">
                  <BootstrapTable
                    ref={table}
                    bootstrap4
                    keyField="id"
                    data={ambassadorData}
                    columns={columns}
                    bordered={false}
                    classes="table-dashboard table-striped table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap"
                    rowClasses="btn-reveal-trigger border-top border-200"
                    headerClasses="bg-200 text-900 border-y border-200"
                    {...paginationTableProps}
                  />
                </div>
                <Row noGutters className="px-1 py-3 flex-center">
                  <Col xs="auto">
                    <Button
                      color="falcon-default"
                      size="sm"
                      onClick={handlePrevPage(paginationProps)}
                      disabled={paginationProps.page === 1}
                    >
                      <FontAwesomeIcon icon="chevron-left" />
                    </Button>
                    {getPaginationArray(paginationProps.totalSize, paginationProps.sizePerPage).map(pageNo => (
                      <Button
                        color={paginationProps.page === pageNo ? 'falcon-primary' : 'falcon-default'}
                        size="sm"
                        className="ml-2"
                        onClick={() => paginationProps.onPageChange(pageNo)}
                        key={pageNo}
                      >
                        {pageNo}
                      </Button>
                    ))}
                    <Button
                      color="falcon-default"
                      size="sm"
                      className="ml-2"
                      onClick={handleNextPage(paginationProps)}
                      disabled={lastIndex >= paginationProps.totalSize}
                    >
                      <FontAwesomeIcon icon="chevron-right" />
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
    </>
  );
};

export default AmbassadorTable;