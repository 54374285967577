import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, Card, CardBody, CustomInput } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { hours, paymentByStatus } from '../../data/dashboard/payments';
import { rgbaColor, themeColors } from '../../helpers/utils';
import { toast } from 'react-toastify';
import {AppContext,AuthContext} from '../../context/Context';
import url from '../../api/url';
import axios from 'axios';
import Cookies from 'js-cookie';
const DatePicker = require("reactstrap-date-picker");

const PaymentsLineChart = () => {
  const [paymentStatus, setPaymentStatus] = useState('success');
  const { isDark } = useContext(AppContext);
  const {auth,setAuth} = useContext(AuthContext);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const [date,setDate] = useState(today.toISOString());
  const handleChange = (value) => {
    setDate(value);
  }
  const [data,setData] = useState(null);
  const [totalForDay,setTotalForDay] = useState(null);

  useEffect(() => {
    const getData= async ( ) => {
      const token = Cookies.get("token");
      await axios
        .get(`${url}/data/volume/${date.substring(0,10)}`, {
          headers: {
            "x-auth-token": token
          }
        })
        .then((res) => {
          setData(res.data);
          toast.success(`Showing data for date ${date.substring(0,10)}`);
        })
        .catch((error) => {
          if(error.response.data.message === "jwt expired") {
            Cookies.remove('token');
            setAuth(false);
          }
          toast.error(error.response.data.message);
        });
    }
    if (!date){
      return
    } else{
      getData();

    }
  },[date])

  useEffect(() => {
    setTotalForDay(data?.Transactions[paymentStatus][data?.Transactions[paymentStatus].length - 1][data?.Transactions[paymentStatus].length - 1])
   
  },[paymentStatus,data])


  const config = {
    data(canvas) {
      const ctx = canvas.getContext('2d');
      const gradientFill = isDark
        ? ctx.createLinearGradient(0, 0, 0, ctx.canvas.height)
        : ctx.createLinearGradient(0, 0, 0, 250);
      gradientFill.addColorStop(0, isDark ? 'rgba(101, 204, 184, 0.5)' : 'rgba(101, 204, 184, 0.3)');
      gradientFill.addColorStop(1, isDark ? 'transparent' : 'rgba(101, 204, 184, 0.5)');

      return {
        labels: hours.map(hour => hour.substring(0, hour.length - 3)),
        datasets: [
          {
            borderWidth: 2,
            data: data?.Transactions[paymentStatus].map((element, index, array) => {return (element[index]/100).toFixed(2)}),
            borderColor: rgbaColor(isDark ? '#3d978b' : '#3d978b', 0.8),
            backgroundColor: '#65ccb8'
          }
        ]
      };
    },
    options: {
      legend: { display: false },
      tooltips: {
        mode: 'x-axis',
        xPadding: 20,
        yPadding: 10,
        displayColors: false,
        callbacks: {
          label: tooltipItem => `${hours[tooltipItem.index]} - ${tooltipItem.yLabel} GBP`,
          title: () => null
        }
      },
      hover: { mode: 'label' },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              show: true,
              labelString: 'Month'
            },
            ticks: {
              fontColor: rgbaColor('#fff', 0.7),
              fontStyle: 600
            },
            gridLines: {
              color: rgbaColor('#fff', 0.1),
              zeroLineColor: rgbaColor('#fff', 0.1),
              lineWidth: 1
            }
          }
        ],
        yAxes: [
          {
            display: false,
            gridLines: {
              color: rgbaColor('#fff', 1)
            }
          }
        ]
      }
    }
  };

  return (
    <Card className="mb-3">
      <CardBody style={{backgroundColor: "#3d978b"}} className="rounded-soft">
        <Row className="text-white align-items-center no-gutters">
          <Col>
            <h4 className="text-white mb-0">Total £{(totalForDay/100).toFixed(2)}</h4>
          </Col>
          <Col xs="auto" className="d-none d-sm-block">
            <CustomInput
              id="ddd"
              type="select"
              bsSize="sm"
              className="mb-3 shadow"
              value={paymentStatus}
              onChange={({ target }) => setPaymentStatus(target.value)}
            >
              <option value="incomplete">Incomplete Payments</option>
              <option value="success">Successful Payments</option>
              <option value="blocked">Blocked Payments</option>
            </CustomInput>
            <DatePicker  value={date} maxDate={today.toISOString()} onChange={(v) => {handleChange(v)}}/>
          </Col>
        </Row>
        <Line data={config.data} options={config.options} width={1618} height={375} />
      </CardBody>
    </Card>
  );
};

export default PaymentsLineChart;
