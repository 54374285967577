// @flow 
import React, {useState,useEffect} from 'react';
import { Form,FormGroup,Label,Input,Button,Card,
  CardBody,
  CardFooter,Container } from 'reactstrap';
import axios from 'axios';
import url from '../../api/url';
import  Cookies  from 'js-cookie';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';


 const CreateAmbassadorFromCgl = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName,setLastName] = useState("");
    const [role,setRole] = useState("Ambassador");
    const [occupation,setOccupation] = useState("Full-time Employed");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("")
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = Cookies.get("ambassador_token");
        await axios
        .post(`${url}/ambassador/create`, {
          first_name: firstName,
          last_name: lastName,
          role:role,
          occupation:occupation,
          email:email,
          phone:phone,
        }, {
            headers: {
              "x-auth-token": token
            }
          })
        .then(res => {
          toast.success("Ambassador Created");
          window.location.href='/ambassador/login'
        })
        .catch(error => {
          toast.error(error.response.data.message); 
        });
      };


    return (
        <Container className="py-6" >
      <Card className="mb-3">
      <FalconCardHeader title="Create Ambassador">
      </FalconCardHeader>
      <CardBody className="bg-light border-top">
           <Form onSubmit={handleSubmit}>
    <FormGroup>
    <Label for="exampleName">First Name</Label>
    <Input type="text" name="name" id="exampleName" onChange={(e) => {setFirstName(e.target.value)}} placeholder="Name" />
  </FormGroup>
  <FormGroup>
    <Label for="exampleName">Last Name</Label>
    <Input type="text" name="name" id="exampleName" onChange={(e) => {setLastName(e.target.value)}} placeholder="Name" />
  </FormGroup>
  <FormGroup>
    <Label for="exampleEmail">Email</Label>
    <Input type="email" name="email" id="exampleEmail" onChange={(e) => {setEmail(e.target.value)}} placeholder="Email" />
  </FormGroup>
  <FormGroup>
    <Label for="examplePhone">Phone</Label>
    <Input type="phone" name="phone" onChange={(e) => {setPhone(e.target.value)}} id="examplePhone" placeholder="Phone" />
  </FormGroup>
  <FormGroup>
    <Label for="exampleOccupation">Occupation</Label>
    <Input type="select" name="select" onChange={(e) =>{setOccupation(e.target.value)}} id="exampleOccupation">
      <option value="Full-time Employed">Full Time Employed</option>
      <option value="Part-time Employed">Part Time Employed</option>
      <option value="Student">Student</option>
      <option value="UnEmployed" >UnEmployed</option>
    </Input>
  </FormGroup>

 
  <Button color="primary">Save</Button>
</Form>
        </CardBody>
        </Card>
        </Container>
    );
};


export default CreateAmbassadorFromCgl;