import React from 'react'

// import the core library.
import ReactEChartsCore from 'echarts-for-react/lib/core';

// Import the echarts core module, which provides the necessary interfaces for using echarts.
import * as echarts from 'echarts/core';

// Import charts, all with Chart suffix
import { BarChart } from 'echarts/charts';

// import components, all suffixed with Component
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';

// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const getColor = (props) => {

    switch (props) {
        case "dark":
            return "#222831"
            break;
      
        case "300":
          return "#222831"
          break;

        case "100":
          return "#a28d88"
          break;
        case "200":
            return "#a28d88"
            break;  
        case "300":
              return "#a28d88"
              break; 
              case "500":
                return "#222831"
                break; 
        default:
            return "#65ccb8"

            break;
    }

}

const tooltipFormatter = params => {
  return  `
<div>
    <h6 class="fs--1 text-700 mb-0">
    <span class="dot me-1 d-inline-block" style="background-color:${
      params[0].borderColor= params[0].color
    }"></span>
      ${params[0].name} : ${params[0].value}
    </h6>
</div>
`;
};

  

    function AmbassadorBarChart({ firstNames,signups}) {
        
        React.useEffect(() =>{
            console.log(firstNames, "In bar chart")
            console.log(signups, "SSSS")
        })

        
        const months = firstNames
        
          const data = signups;
          
          const getOption = () => ({
            tooltip: {
              trigger: 'axis',
              padding: [7, 10],
              backgroundColor: getColor('100'),
              borderColor: getColor('300'),
              textStyle: { color: getColor('dark') },
              borderWidth: 1,
              formatter: tooltipFormatter,
              transitionDuration: 0,
              axisPointer: {
                type: 'none'
              }
            },
            xAxis: {
              type: 'value',
              boundaryGap: '0%',
              axisLine: {
                show: true,
                lineStyle: {
                  color: getColor('300')
                }
              },
              axisTick: { show: true },
              axisLabel: {
                color: getColor('500')
              },
              splitLine: {
                show: false
              },
              minInterval: 1,
              
            },
            yAxis: {
              type: 'category',
              data: months,
              boundaryGap: '0%',
              axisLabel: {
                formatter: value => value.substring(0, 3),
                show: true,
                color: getColor('500'),
                margin: 15
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: getColor('200')
                }
              },
              axisTick: { show: false },
              axisLine: {
                lineStyle: {
                  color: getColor('300')
                }
              }
            },
            series: [
              {
                type: 'bar',
                name: 'Total',
                data,
                lineStyle: { color: getColor('primary') },
                itemStyle: {
                  color: getColor('primary'),
                  borderRadius: [0, 3, 3, 0]
                },
                showSymbol: false,
                symbol: 'circle',
                smooth: false,
                emphasis: {
                  scale: true
                }
              }
            ],
            grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
            });
    
    return (
      <ReactEChartsCore
        echarts={echarts}
        option={getOption()}
        style={{ height: '20rem' }}
      />
    );
    }
    
    export default AmbassadorBarChart;
    